import React from 'react'
import {graphql} from "gatsby";

const NotFoundPage = () => {

        // Skip build, Browsers only
        if (typeof window !== 'undefined') {
            // const { langs, defaultLangKey } = this.props.data.site.siteMetadata.languages;
            // const referrer = document.referrer
            // const path = window.location.pathname
            // let locale
            let locale = 'nl-NL'
            // let localeString = referrer ? referrer.split('/')[2] : path.split('/')[1]
            // if (langs.map( l => l.toLowerCase() ).indexOf(localeString.toLowerCase()) !== -1) {
            //     locale = localeString
            // } else  locale = 'nl-NL';
            const errorUrl = `/${locale.toLowerCase()}/404/`;
            // I don`t think this is the best solution
            // I would like to use Gatsby Redirects like:
            // https://github.com/gatsbyjs/gatsby/tree/master/examples/using-redirects
            // But Gatsby Redirects are static, they need to be specified at build time,
            // This redirect is dynamic, It needs to know the user browser language.
            // Any ideias? Join the issue: https://github.com/angeloocana/gatsby-starter-default-i18n/issues/4
            //window.___replace(errorUrl);
            window.location.href = errorUrl;
        }

        return (<div />);
}

export default NotFoundPage

export const query = graphql`
    query {
        site {
            siteMetadata {
                languages {
                    defaultLangKey
                    langs
                }
            }
        }
    }
`
